import { OrganizationEventResumeModel } from './OrganizationEventResumeModel';
import { OrganizationEventLatestRegistrationModel } from './OrganizationEventLatestRegistrationModel';
import { DateTime } from 'luxon';

export enum LicenseType {
  None = 'null',
  Light = 'light',
  Club = 'club',
  Pro = 'pro',
  Corporate = 'corporate',
}

export class OrganizationModel {

  public static empty() {
    return new OrganizationModel();
  }

  public id: number | null = null;
  private _name: string;
  public description: string;
  public logo: string;
  public slug: string;
  public publicName: string;
  public contactName: string;
  public contactEmail: string;
  public contactPhone: string;
  public contactInfo: string;
  public displayPublicList: boolean;
  public displayPublicContact: boolean;
  public enableCampaigns: boolean;
  public enableGroupRegistrations: boolean;
  public enforce2fa: boolean;
  public licenseType: LicenseType;
  public validUntil: string | null;
  public enableWeezevent: boolean;
  public enableShop: boolean;
  public enableBadges: boolean;
  public enableVouchers: boolean;
  public enableParticipationsValidation: boolean;
  public nbPendingValidations: number;


  public latestRegistration: OrganizationEventLatestRegistrationModel[] = [];
  public eventsResume: OrganizationEventResumeModel = new OrganizationEventResumeModel();
  public nbrVolunteers: number = 0;
  public defaultLanguage = 'fr';

  constructor({
    id = null,
    name = '',
    description = '',
    logo = '',
    slug = '',
    publicName = '',
    contactName = '',
    contactEmail = '',
    contactPhone = '',
    contactInfo = '',
    displayPublicList = true,
    displayPublicContact = true,
    enableCampaigns = false,
    enableGroupRegistrations = false,
    enforce2fa = false,
    licenseType = LicenseType.None,
    enableWeezevent = false,
    enableShop = false,
    enableBadges = false,
    enableVouchers = false,
    enableParticipationsValidation = false,
    nbPendingValidations = 0,
    defaultLanguage = 'fr',
    validUntil = null,
  }: {
    id?: number | null,
    name?: string,
    description?: string,
    logo?: string,
    slug?: string,
    publicName?: string,
    contactName?: string,
    contactEmail?: string,
    contactPhone?: string,
    contactInfo?: string,
    displayPublicList?: boolean,
    displayPublicContact?: boolean,
    enableCampaigns?: boolean,
    enableGroupRegistrations?: boolean,
    enforce2fa?: boolean,
    licenseType?: LicenseType,
    enableWeezevent?: boolean,
    enableShop?: boolean,
    enableBadges?: boolean,
    enableVouchers?: boolean,
    nbPendingValidations?: number,
    enableParticipationsValidation?: boolean,
    defaultLanguage?: string,
    validUntil?: string | null,
  } = {}) {
    this.id = id;
    this._name = name;
    this.description = description;
    this.logo = logo;
    this.slug = slug;
    if (publicName) {
      this.publicName = publicName;
    } else {
      this.publicName = name;
    }
    this.defaultLanguage = defaultLanguage;
    this.nbPendingValidations = nbPendingValidations;
    this.contactName = contactName;
    this.contactInfo = contactInfo;
    this.contactEmail = contactEmail;
    this.contactPhone = contactPhone;
    this.displayPublicContact = displayPublicContact;
    this.displayPublicList = displayPublicList;
    this.enableCampaigns = enableCampaigns;
    this.enableGroupRegistrations = enableGroupRegistrations;
    this.enforce2fa = enforce2fa;
    this.licenseType = licenseType;
    this.enableWeezevent = enableWeezevent;
    this.enableShop = enableShop;
    this.enableBadges = enableBadges;
    this.enableVouchers = enableVouchers;
    this.enableParticipationsValidation = enableParticipationsValidation;
    this.validUntil = validUntil;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      slug: this.slug,
      logo: this.logo,
      publicName: this.publicName,
      contactEmail: this.contactEmail,
      contactInfo: this.contactInfo,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      displayPublicContact: this.displayPublicContact,
      displayPublicList: this.displayPublicList,
      enableCampaigns: this.enableCampaigns,
      enableGroupRegistrations: this.enableGroupRegistrations,
      enforce2fa: this.enforce2fa,
      licenseType: this.licenseType,
      nbPendingValidations: this.nbPendingValidations,
      validUntil: this.validUntil,
    };
  }

  public get name(): string {
    return this.publicName !== '' ?
      this.publicName :
      this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get licenseName() {
    if (this) {
      if (this.licenseType === LicenseType.Pro) {
        return "PRO";
      } else if (this.licenseType === LicenseType.Corporate) {
        return "CORPORATE";
      } else if (this.licenseType === LicenseType.Club) {
        return "CLUB";
      } else if (this.licenseType === LicenseType.Light) {
        return "LIGHT";
      }
    }
    return '-';
  }
}
